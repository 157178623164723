// UserActivityComponent.js
import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import styles from "./profile.module.scss";
import Navbar from "../../components/navbar/Navbar";
import i18n from "../../config/i18n";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useTranslation, I18nextProvider } from 'react-i18next';


const UserActivityComponent = () => {
  const [activities, setActivities] = useState([]);
  const [activityCounts, setActivityCounts] = useState({});
  const { currentUser } = useContext(AuthContext);
  const [pageVisits, setPageVisits] = useState(null);
  const { t, i18n  } = useTranslation();
  const browserLanguage = navigator.language.substring(0, 2);


  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    i18n.changeLanguage(newLanguage);
    
  };


  useEffect(() => {
    
    const fetchUserActivities = async () => {
      const activitiesQuery = query(collection(db, "user_activities"), where("userId", "==", currentUser.uid));
      const querySnapshot = await getDocs(activitiesQuery);
      const fetchedActivities = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp ? doc.data().timestamp.toDate().toString() : 'No timestamp',
      }));
    i18n.changeLanguage(browserLanguage);
    const select = document.getElementById("language-select");
    select.value = browserLanguage;
      const counts = fetchedActivities.reduce((acc, activity) => {
        const key = activity.details && activity.details.id;
        if (activity.action === 'button_click' && key) {
          acc[key] = (acc[key] || 0) + 1;
        }
        return acc;
      }, {});

      setActivities(fetchedActivities);
      setActivityCounts(counts);
    };

    if (currentUser && currentUser.uid) {
      fetchUserActivities();
    }
  }, [currentUser]);

  useEffect(() => {
    // ... mevcut aktivite çekme işlemleriniz

    // Sayfa ziyaretlerini çekme ve sayma işlemi
    const fetchPageVisits = async () => {
      const visitsQuery = query(collection(db, "page_visits"), where("path", "==", (`/`+currentUser.uid)));
      const querySnapshot = await getDocs(visitsQuery);
      const visitCounts = querySnapshot.docs.length; // Doküman sayısını al
      setPageVisits(visitCounts); // State'i güncelle
    };

    if (currentUser && currentUser.uid) {
      fetchPageVisits();
    }
  }, [currentUser]);

  // Pie chart data
  const data = {
    labels: Object.keys(activityCounts),
    datasets: [
      {
        data: Object.values(activityCounts),
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#FFCD56',
          '#C9CBCF',
          '#FF6384',
          '#36A2EB',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#C0C0C0',
          '#808080',
          '#800080',
          '#00FF00',
          '#0000FF',
          '#FF0000',
        ],
        hoverBackgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#FFCD56',
          '#C9CBCF',
          '#FF6384',
          '#36A2EB',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#C0C0C0',
          '#808080',
          '#800080',
          '#00FF00',
          '#0000FF',
          '#FF0000',
        ],
      },
    ],
  };

  return (

    <div>

      <Navbar />
      <h1 className={styles.pageTitle} >{t('statistics.statistics')}</h1>
    <div className={styles.panelContainer}>

      <div className={styles.panelItem}>
        <div className={styles.chartContainer}>
          <h1 className={styles.panelTitle}>{t('statistics.buttonactivities')}</h1>
          <Pie className={styles.pieChart} data={data} />
        </div>
      </div>

      <div className={styles.panelItem }>
      <div className={styles.chartContainer}>
        <h1 className={styles.panelTitle}>{t('statistics.statistics')}</h1>
        <table className={styles.table}>
          <tbody>
            <tr>
              <th>{t('statistics.pagevisits')}</th>
              <td className={styles.pageVisits}>{pageVisits}</td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
    </div>
    </div>
    
  );
};

export default UserActivityComponent;
