import "./profile.scss";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LanguageIcon from "@mui/icons-material/Language";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import { BsTiktok, BsWhatsapp } from 'react-icons/bs';
import { TbBrandTiktok } from 'react-icons/tb';
import { collection, query, getDocs,doc, getDoc, addDoc, where } from "firebase/firestore";
import { logEvent  } from 'firebase/analytics';
import { auth, db,analytics } from "../../firebase";
import { useEffect, useRef } from "react";
import { useTranslation, I18nextProvider } from 'react-i18next';
import i18n from "../../config/i18n";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useParams, Navigate ,useNavigate} from 'react-router-dom';



import QRCode from "qrcode.react";
import { useState } from "react";
import { Facebook, Instagram, Key, LinkedIn, LocationCity, Mail, YouTube } from "@mui/icons-material";



const Profile = () => {
  const user = auth.currentUser;

  const [showAddressModal, setAddressShowModal] = useState(false);
  const [showBankModal, setBankShowModal] = useState(false);
  const [showCompanyModal, setCompanyShowModal] = useState(false);
  const [showCompanyModal2, setCompanyShowModal2] = useState(false);
  const textAddressRef = useRef(null);
  const textBankRef = useRef(null);
  const textCompanyRef = useRef(null);
  const textCompanyRef2 = useRef(null);
  const [modalAddressData, setAddressModalData] = useState("");
  const [modalBankData, setBankModalData] = useState("");
  const [modalCompanyData, setCompanyModalData] = useState("");
  const [modalCompanyData2, setCompanyModalData2] = useState("");
  const { t, i18n  } = useTranslation();
  const browserLanguage = navigator.language.substring(0, 2);
  const [userTemplate, setUserTemplate] = useState("");

  const boxStyle = userTemplate === "template-box-container" ? { display: "none" } : {};
  const templateBoxContainerStyle = userTemplate === "box" ? { display: "none" } : {};
  const socialStyle = userTemplate === "template-box-container" ? { display: "none" } : {};

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    i18n.changeLanguage(newLanguage);
    
  };
  const [backgroundColor, setBackgroundColor] = useState('#343434');
  const [textColor, setTextColor] = useState('#e3e7e5');
  const [iconColor, setIconColor] = useState('#343434');
  const [iconBoxColor, setIconBoxColor] = useState('#f8f8f8');
  const navigate = useNavigate();

  const customizeLabel = (detailId) => {
    const customLabels = {
      "vcfLink": "Rehbere Eklenme Sayısı",
      "bottom-instagram-button": "Instagram",
      "bottom-facebook-button": "Facebook",
      "bottom-address-button": "Adres",
      "bottom-bankIban-button": "Iban",
      "bottom-officialName-button": "Şirket Bilgisi",
      "bottom-officialName2-button": "2. Şirket Bilgisi",
      "bottom-trendyol-button": "Trendyol",
      "bottom-hepsiburada-button": "Hepsiburada",
      "bottom-sahibinden-button": "Sahibinden",
      "bottom-amazon-button": "Amazon",
      "box-instagram-button": "Instagram",
      "box-facebook-button": "Facebook",
      "box-address-button": "Adres",
      "box-bankIban-button": "Iban",
      "box-officialName-button": "Şirket Bilgisi",
      "box-officialName2-button": "2. Şirket Bilgisi",
      "box-trendyol-button": "Trendyol",
      "box-hepsiburada-button": "Hepsiburada",
      "box-sahibinden-button": "Sahibinden",
      "box-amazon-button": "Amazon",
      "phone": "Telefon Araması",
      "email": "Email",
      "whatsapp": "Whatsapp",
      "website": "Website",
      "instagram": "Instagram",
      "facebook": "Facebook",
      "linkedin": "Linkedin",
      "tiktok": "Tiktok",
      "twitter": "Twitter",
      "youtube": "Youtube",


      
    };
  
    return customLabels[detailId] || detailId;
  }

  const { identifier } = useParams();

  console.log(identifier);
  useEffect(() => {
    i18n.changeLanguage(browserLanguage);
    const select = document.getElementById("language-select");
    select.value = browserLanguage;
    let userFound = false;

    async function fetchUserData() {

      // Hem UID hem de kullanıcı adına göre sorgu yap
      const q = query(collection(db, "users"), where("uid", "==", identifier));
      const qUsername = query(collection(db, "username"), where("username", "==", identifier));

      try {
        let q;

        if (identifier.length === 29) {
          // Eğer identifier 29 karakter uzunluğunda ise, bu bir uid'dir
          q = query(collection(db, "users"), where("uid", "==", identifier));
        } else {
          // Eğer 29 karakter değilse, bu bir username'dir
          q = query(collection(db, "users"), where("username", "==", identifier));
        }
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          const userDoc = doc.data();
          if ( doc.data().uid === userDoc.uid) {
            var keys = Object.keys(doc.data());
            userFound=true;
            keys.forEach((key) => {
              const keyData = doc.data()[String(key)];
              if (key !== null && key === "template") {
                if (doc.data()[String(key)] !== "") {
                  setUserTemplate(doc.data()[String(key)]);
                } 
              }
              if(key !== null && typeof keyData === 'string' && keyData.trim() === ""){
                const element =  document.getElementById(key);
                if(element){
                  element.style.display = "none";
                  const button = document.getElementById(`bottom-${key}-button`);
                  const Boxbutton = document.getElementById(`box-${key}-button`);
                  if(button ){
                    button.style.display ="none";
                    if(Boxbutton){
                      Boxbutton.style.display ="none";
                    }
                  }
                  else if(Boxbutton ){
                    Boxbutton.style.display ="none";
                    if(button){
                      button.style.display ="none";
                    }
                  }
  
                }
                else{
                  const button = document.getElementById(`bottom-${key}-button`);
                  const Boxbutton = document.getElementById(`box-${key}-button`);
  
                  if(button ){
                    button.style.display ="none";
                    if(Boxbutton){
                      Boxbutton.style.display ="none";
                    }
                  }
                  else if(Boxbutton ){
                    Boxbutton.style.display ="none";
                    if(button){
                      button.style.display ="none";
                    }
                  }
                }
  
              }
              if (key !== null && key === "img") {
                const img = document.getElementById(key);
                if (doc.data()[String(key)] !== "") {
                  img.src = doc.data()[String(key)];
                } else {
                  img.style.display = "none";
                }
              }
              if (key !== null && key === "logo") {
                const img = document.getElementById(key);
                if (doc.data()[String(key)] !== "") {
                  img.src = doc.data()[String(key)];
                } else {
                  img.style.display = "none";
                }
              }
              if (key !== null && key === "backgroundColor") {
  
                setBackgroundColor(doc.data().backgroundColor);
              }
              if (key !== null && key === "textColor") {
  
                setTextColor(doc.data().textColor);
              }
              if (key !== null && key === "iconColor") {
  
                setIconColor(doc.data().iconColor);
              }
              if (key !== null && key === "iconBoxColor") {
  
                setIconBoxColor(doc.data().iconBoxColor);
              }
              if (key !== null && key === "name") {
                const name = document.getElementById(key);
                name.innerHTML =
                  doc.data()[String(key)] +" "+ doc.data()[String("surname")];
                  document.title=doc.data()[String(key)] +" "+ doc.data()[String("surname")];
                  logEvent(analytics, 'page_view', {
                    page_title:document.title,
  
                  });
              }
              if (key !== null && key === "job") {
                const job = document.getElementById(key);
                job.innerHTML = doc.data()[String(key)];
              }
              if (key !== null && key === "note") {
                const note = document.getElementById(key);
                note.innerHTML = doc.data()[String(key)];
              }
              if (key !== null && key === "company") {
                const company = document.getElementById(key);
                company.innerHTML = doc.data()[String(key)];
              }
              if (key !== null && key === "address") {
                const keyData = doc.data()[String(key)];
                if (keyData && keyData.trim() !== "") {
                  document.getElementById("button-text").innerHTML = keyData;
                } 
              }
              if (key !== null && key === "bankName") {
                const keyData = doc.data()[String(key)];
                if (keyData && keyData.trim() !== "") {
                  document.getElementById("bankName").innerHTML = keyData;
                  document.getElementById("BoxbankName").innerHTML = keyData;
                } 
              }
              if (key !== null && key === "bankIban") {
                const keyData = doc.data()[String(key)];
                if (keyData && keyData.trim() !== "") {
                  document.getElementById("bankIban").innerHTML = keyData;
                  document.getElementById("BoxbankIban").innerHTML = keyData;
                } 
              }
              if (key !== null && key === "bankRecipient") {
                const keyData = doc.data()[String(key)];
                if (keyData && keyData.trim() !== "") {
                  document.getElementById("bankRecipient").innerHTML = keyData;
                  document.getElementById("BoxbankRecipient").innerHTML = keyData;
                } 
              }
              if (key !== null && key === "officialName") {
                const keyData = doc.data()[String(key)];
                if (keyData && keyData.trim() !== "") {
                  document.getElementById("officialName").innerHTML = keyData;
                  document.getElementById("BoxofficialName").innerHTML = keyData;
                } 
              }
              if (key !== null && key === "officalAddress") {
                const keyData = doc.data()[String(key)];
                if (keyData && keyData.trim() !== "") {
                  document.getElementById("officalAddress").innerHTML = keyData;
                  document.getElementById("BoxofficalAddress").innerHTML = keyData;
                } 
              }
              if (key !== null && key === "officialVAT") {
                const keyData = doc.data()[String(key)];
                if (keyData && keyData.trim() !== "") {
                  document.getElementById("officialVAT").innerHTML = keyData;
                  document.getElementById("BoxofficialVAT").innerHTML = keyData;
                } 
              }
  
              if (key !== null && key === "officialName2") {
                const keyData = doc.data()[String(key)];
                if (keyData && keyData.trim() !== "") {
                  document.getElementById("officialName2").innerHTML = keyData;
                  document.getElementById("BoxofficialName2").innerHTML = keyData;
                } 
              }
              if (key !== null && key === "officalAddress2") {
                const keyData = doc.data()[String(key)];
                if (keyData && keyData.trim() !== "") {
                  document.getElementById("officalAddress2").innerHTML = keyData;
                  document.getElementById("BoxofficalAddress2").innerHTML = keyData;
                } 
              }
              if (key !== null && key === "officialVAT2") {
                const keyData = doc.data()[String(key)];
                if (keyData && keyData.trim() !== "") {
                  document.getElementById("officialVAT2").innerHTML = keyData;
                  document.getElementById("BoxofficialVAT2").innerHTML = keyData;
                } 
              }

            });
          }
  

        });
        if(!userFound){
          getFirstData();
        }


      } catch (error) {
        console.error("Veri yüklenirken bir hata oluştu: ", error);
      }
    }

    fetchUserData();
    
  }, [identifier]);
  async function getFirstData() {
    // const q = query(collection(db, "users",user.uid));
    const q = query(collection(db, "users"));
    const querySnapshot = await getDocs(q);
    let userFound = false;

    querySnapshot.forEach((doc) => {

      if ("/" + doc.data().uid === window.location.pathname) {
        var keys = Object.keys(doc.data());
        userFound=true;
        keys.forEach((key) => {
          const keyData = doc.data()[String(key)];
          if (key !== null && key === "template") {
            if (doc.data()[String(key)] !== "") {
              setUserTemplate(doc.data()[String(key)]);
            } 
          }
          if(key !== null && typeof keyData === 'string' && keyData.trim() === ""){
            const element =  document.getElementById(key);
            if(element){
              element.style.display = "none";
              const button = document.getElementById(`bottom-${key}-button`);
              const Boxbutton = document.getElementById(`box-${key}-button`);
              if(button ){
                button.style.display ="none";
                if(Boxbutton){
                  Boxbutton.style.display ="none";
                }
              }
              else if(Boxbutton ){
                Boxbutton.style.display ="none";
                if(button){
                  button.style.display ="none";
                }
              }

            }
            else{
              const button = document.getElementById(`bottom-${key}-button`);
              const Boxbutton = document.getElementById(`box-${key}-button`);

              if(button ){
                button.style.display ="none";
                if(Boxbutton){
                  Boxbutton.style.display ="none";
                }
              }
              else if(Boxbutton ){
                Boxbutton.style.display ="none";
                if(button){
                  button.style.display ="none";
                }
              }
            }

          }
          if (key !== null && key === "img") {
            const img = document.getElementById(key);
            if (doc.data()[String(key)] !== "") {
              img.src = doc.data()[String(key)];
            } else {
              img.style.display = "none";
            }
          }
          if (key !== null && key === "logo") {
            const img = document.getElementById(key);
            if (doc.data()[String(key)] !== "") {
              img.src = doc.data()[String(key)];
            } else {
              img.style.display = "none";
            }
          }
          if (key !== null && key === "backgroundColor") {

            setBackgroundColor(doc.data().backgroundColor);
          }
          if (key !== null && key === "textColor") {

            setTextColor(doc.data().textColor);
          }
          if (key !== null && key === "iconColor") {

            setIconColor(doc.data().iconColor);
          }
          if (key !== null && key === "iconBoxColor") {

            setIconBoxColor(doc.data().iconBoxColor);
          }
          if (key !== null && key === "name") {
            const name = document.getElementById(key);
            name.innerHTML =
              doc.data()[String(key)] +" "+ doc.data()[String("surname")];
              document.title=doc.data()[String(key)] +" "+ doc.data()[String("surname")];
              logEvent(analytics, 'page_view', {
                page_title:document.title,

              });
          }
          if (key !== null && key === "job") {
            const job = document.getElementById(key);
            job.innerHTML = doc.data()[String(key)];
          }
          if (key !== null && key === "note") {
            const note = document.getElementById(key);
            note.innerHTML = doc.data()[String(key)];
          }
          if (key !== null && key === "company") {
            const company = document.getElementById(key);
            company.innerHTML = doc.data()[String(key)];
          }
          if (key !== null && key === "address") {
            const keyData = doc.data()[String(key)];
            if (keyData && keyData.trim() !== "") {
              document.getElementById("button-text").innerHTML = keyData;
            } 
          }
          if (key !== null && key === "bankName") {
            const keyData = doc.data()[String(key)];
            if (keyData && keyData.trim() !== "") {
              document.getElementById("bankName").innerHTML = keyData;
              document.getElementById("BoxbankName").innerHTML = keyData;
            } 
          }
          if (key !== null && key === "bankIban") {
            const keyData = doc.data()[String(key)];
            if (keyData && keyData.trim() !== "") {
              document.getElementById("bankIban").innerHTML = keyData;
              document.getElementById("BoxbankIban").innerHTML = keyData;
            } 
          }
          if (key !== null && key === "bankRecipient") {
            const keyData = doc.data()[String(key)];
            if (keyData && keyData.trim() !== "") {
              document.getElementById("bankRecipient").innerHTML = keyData;
              document.getElementById("BoxbankRecipient").innerHTML = keyData;
            } 
          }
          if (key !== null && key === "officialName") {
            const keyData = doc.data()[String(key)];
            if (keyData && keyData.trim() !== "") {
              document.getElementById("officialName").innerHTML = keyData;
              document.getElementById("BoxofficialName").innerHTML = keyData;
            } 
          }
          if (key !== null && key === "officalAddress") {
            const keyData = doc.data()[String(key)];
            if (keyData && keyData.trim() !== "") {
              document.getElementById("officalAddress").innerHTML = keyData;
              document.getElementById("BoxofficalAddress").innerHTML = keyData;
            } 
          }
          if (key !== null && key === "officialVAT") {
            const keyData = doc.data()[String(key)];
            if (keyData && keyData.trim() !== "") {
              document.getElementById("officialVAT").innerHTML = keyData;
              document.getElementById("BoxofficialVAT").innerHTML = keyData;
            } 
          }

          if (key !== null && key === "officialName2") {
            const keyData = doc.data()[String(key)];
            if (keyData && keyData.trim() !== "") {
              document.getElementById("officialName2").innerHTML = keyData;
              document.getElementById("BoxofficialName2").innerHTML = keyData;
            } 
          }
          if (key !== null && key === "officalAddress2") {
            const keyData = doc.data()[String(key)];
            if (keyData && keyData.trim() !== "") {
              document.getElementById("officalAddress2").innerHTML = keyData;
              document.getElementById("BoxofficalAddress2").innerHTML = keyData;
            } 
          }
          if (key !== null && key === "officialVAT2") {
            const keyData = doc.data()[String(key)];
            if (keyData && keyData.trim() !== "") {
              document.getElementById("officialVAT2").innerHTML = keyData;
              document.getElementById("BoxofficialVAT2").innerHTML = keyData;
            } 
          }


        });
      }
    });
    if (!userFound) {
      navigatetoLogin();
    }
  }
  function navigatetoLogin(){
      navigate('/login');
  }


  function handleCopyAdressText() {
    navigator.clipboard.writeText(textAddressRef.current.innerText);
    setAddressModalData(textAddressRef.current.innerText);
    setAddressShowModal(true);
  }
  function closeAdressModal() {
    setAddressShowModal(false);
  }
  //addressModal

  //BankModal
  function handleCopyBankText() {
    navigator.clipboard.writeText(textBankRef.current.innerText);
    setBankModalData(textBankRef.current.innerText);
    setBankShowModal(true);
  }
  function closeBankModal() {
    setBankShowModal(false);
  }
  //BankModal

  //CompanyModal
  function handleCopyCompanyText() {
    navigator.clipboard.writeText(textCompanyRef.current.innerText);
    setCompanyModalData(textCompanyRef.current.innerText);
    setCompanyShowModal(true);
  }
  function handleCopyCompanyText2() {
    navigator.clipboard.writeText(textCompanyRef2.current.innerText);
    setCompanyModalData2(textCompanyRef2.current.innerText);
    setCompanyShowModal2(true);
  }
  function closeCompanyModal() {
    setCompanyShowModal(false);
  }
  //CompanyModal
  function closeCompanyModal2() {
    setCompanyShowModal2(false);
  }

  async function SelectData(id) {
    // const q = query(collection(db, "users",user.uid));
    const q = query(collection(db, "users"));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {

      if ("/" + doc.data().uid === window.location.pathname) {
        var keys = Object.keys(doc.data());
        keys.forEach((key) => {

          if (key !== null &&key === id && ( key === "instagram" )) {
            window.location.href =
               doc.data()[String(key)];
          }
          if (key !== null && key === id && (key === "facebook" )) {
            window.location.href =
               doc.data()[String(key)];
          }

          if (key !== null &&  key === id && (key === "linkedin")) {
            window.location.href =
              doc.data()[String(key)];
          }        
          
          if (key !== null &&  key === id && (key === "tiktok")) {
            window.location.href =
               doc.data()[String(key)];
          }   
          if (key !== null &&  key === id && (key === "twitter")) {
            window.location.href =
               doc.data()[String(key)];
          }      
          if (key !== null && key === id && key === "vcfLink") {
            window.location.href = doc.data()[String(key)];
          }
          if (key !== null && key === id && key === "website") {
            window.location.href = doc.data()[String(key)];
            if (!doc.data()[String(key)].startsWith('http://') && !doc.data()[String(key)].startsWith('https://')) {
              window.location.href = 'http://' + doc.data()[String(key)]; // veya 'https://' eğer güvenli bir bağlantıysa
            } else {
              window.location.href = doc.data()[String(key)];
            }
          }
          if (key !== null && key === id && key === "phone") {
            window.location.href = "tel:" + doc.data()[String(key)];
          }
          if (key !== null && key==="phone"&& id === "whatsapp") {
            window.location.href = "https://wa.me/" + doc.data()[String("phone")];
          }
          if (key !== null && key === id && key === "email") {
            window.location.href = "mailto:" + doc.data()[String(key)];
          }

          if (key !== null && key === id && key === "img") {
            const img = document.getElementById(key);
            img.src = doc.data()[String(key)];
          }
          if (key !== null && key === id && key === "address") {
            const addressValue = doc.data()[String(key)];
            window.location.href =
              "http://maps.google.com/maps?q=" + addressValue;
          }
           if (key !== null && key === id && key === "trendyol") {
            console.log(doc.data()[String(key)]);
             const addressValue = doc.data()[String(key)];
             window.location.href = addressValue;
           }
           if (key !== null && key === id && key === "hepsiburada") {
            console.log(doc.data()[String(key)]);
             const addressValue = doc.data()[String(key)];
             window.location.href = addressValue;
           }
           if (key !== null && key === id && key === "sahibinden") {
            console.log(doc.data()[String(key)]);
             const addressValue = doc.data()[String(key)];
             window.location.href = addressValue;
           }
           if (key !== null && key === id && key === "amazon") {
            console.log(doc.data()[String(key)]);
             const addressValue = doc.data()[String(key)];
             window.location.href = addressValue;
           }
        });
      }
      else if (doc.data().username === identifier) {
        var keys = Object.keys(doc.data());
        keys.forEach((key) => {

          if (key !== null &&key === id && ( key === "instagram" )) {
            window.location.href =
               doc.data()[String(key)];
          }
          if (key !== null && key === id && (key === "facebook" )) {
            window.location.href =
               doc.data()[String(key)];
          }

          if (key !== null &&  key === id && (key === "linkedin")) {
            window.location.href =
              doc.data()[String(key)];
          }        
          
          if (key !== null &&  key === id && (key === "tiktok")) {
            window.location.href =
               doc.data()[String(key)];
          }   
          if (key !== null &&  key === id && (key === "twitter")) {
            window.location.href =
               doc.data()[String(key)];
          }      
          if (key !== null && key === id && key === "vcfLink") {
            window.location.href = doc.data()[String(key)];
          }
          if (key !== null && key === id && key === "website") {
            window.location.href = doc.data()[String(key)];
            if (!doc.data()[String(key)].startsWith('http://') && !doc.data()[String(key)].startsWith('https://')) {
              window.location.href = 'http://' + doc.data()[String(key)]; // veya 'https://' eğer güvenli bir bağlantıysa
            } else {
              window.location.href = doc.data()[String(key)];
            }
          }
          if (key !== null && key === id && key === "phone") {
            window.location.href = "tel:" + doc.data()[String(key)];
          }
          if (key !== null && key==="phone"&& id === "whatsapp") {
            window.location.href = "https://wa.me/" + doc.data()[String("phone")];
          }
          if (key !== null && key === id && key === "email") {
            window.location.href = "mailto:" + doc.data()[String(key)];
          }

          if (key !== null && key === id && key === "img") {
            const img = document.getElementById(key);
            img.src = doc.data()[String(key)];
          }
          if (key !== null && key === id && key === "address") {
            const addressValue = doc.data()[String(key)];
            window.location.href =
              "http://maps.google.com/maps?q=" + addressValue;
          }
           if (key !== null && key === id && key === "trendyol") {
            console.log(doc.data()[String(key)]);
             const addressValue = doc.data()[String(key)];
             window.location.href = addressValue;
           }
           if (key !== null && key === id && key === "hepsiburada") {
            console.log(doc.data()[String(key)]);
             const addressValue = doc.data()[String(key)];
             window.location.href = addressValue;
           }
           if (key !== null && key === id && key === "sahibinden") {
            console.log(doc.data()[String(key)]);
             const addressValue = doc.data()[String(key)];
             window.location.href = addressValue;
           }
           if (key !== null && key === id && key === "amazon") {
            console.log(doc.data()[String(key)]);
             const addressValue = doc.data()[String(key)];
             window.location.href = addressValue;
           }
        });
      }
    });
  }
  const [title, setTitle] = useState('');


  useEffect(() => {
    // Diyelim ki bu fonksiyon asenkron bir kaynaktan veri çekiyor
    const fetchTitle = async () => {
      // Asenkron işlemi temsil eden bir timeout
      setTimeout(() => {
        // Veri çekildiğinde başlığı güncelle
        const fetchedTitle = document.getElementById("name").innerHTML;
        setTitle(fetchedTitle);
        document.title = fetchedTitle; // Sayfa başlığını güncelle
      }, 100); // 1 saniye sonra veri "çekildi"
    };

    fetchTitle();
  }, []);

  useEffect(() => {
    // Veritabanına sayfa ziyaretini kaydet
    const logPageVisit = async () => {
      try {
        await addDoc(collection(db, "page_visits"), {
          path: window.location.pathname, // Sayfa yolu
          timestamp: new Date(), // Ziyaret zamanı
        });
      } catch (error) {
        console.error("Sayfa ziyareti kaydedilemedi:", error);
      }
    };
  
    logPageVisit();
  }, []);
  
  const handleLink = (e) => {
    e.preventDefault();
    const id = e.target.id;
    let dataKey = id;
    
    if (id.includes('bottom-')) {
      const key = id.split('bottom-')[1].split('-button')[0];
      dataKey = key;
    }
    else if (id.includes('box-')) {
      const key = id.split('box-')[1].split('-button')[0];
      dataKey = key;
    }
    const buttonId = e.target.id;

  const logUserActivity = async (activity) => {
    await addDoc(collection(db, "user_activities"), {
      ...activity,
      timestamp: new Date(), // Etkinliğin zaman damgası
    });
  };
  
  const pagename=document.getElementById("name").innerHTML;


    // Firebase Analytics'e özel etkinlik gönderme
    logEvent(analytics, buttonId, {

      id: customizeLabel(buttonId),
      name: customizeLabel(buttonId),
      // Diğer özel parametreler
    });
    logUserActivity({
      userId: window.location.pathname.split('/').pop(),
      action: 'button_click',
      details: {
        id: customizeLabel(buttonId),
        name: customizeLabel(buttonId),
        page: pagename+ "Profile"        // Diğer detaylar
      }
    });


    
    SelectData(dataKey);
  };
  return (
    <div className="profile">
      <div className="profileContainer">
        <div className="top" style={{ backgroundColor:backgroundColor }}>
          <div className="logo">
            <img src="" alt="" className="logoImg" id="logo" />
          </div>
          <div className="item">
            <img src="" alt="" className="itemImg" id="img" />
          </div>
          <div className="language-select-div">
        <select style={{ color:textColor }} id="language-select" onChange={handleLanguageChange}>
          <option style={{ textColor }} value="en">EN</option>
          <option value="de">DE</option>
          <option value="tr">TR</option>
          <option value="ru">RU </option>
        </select>
      </div>
          <div className="profile-info">
              <h1 style={{ color:textColor }} className="title" id="name">
                {" "}
              </h1>
              <h2 style={{ color:textColor }} className="company" id="company">
                {" "}
              </h2>
              <h2 style={{ color:textColor }} className="job" id="job">
                {" "}
              </h2>
              <p style={{ color:textColor }} className="note" id="note">{" "}</p>
          </div>

          <div style={socialStyle} className="social">
            <button style={{ borderColor:textColor, backgroundColor:iconBoxColor }}  onClick={handleLink} id="phone">
              {" "}
              <CallOutlinedIcon style={{ color:iconColor }} className="icon" />
            </button>
            <button style={{ borderColor:textColor, backgroundColor:iconBoxColor }} onClick={handleLink} id="email">
              {" "}
              <EmailOutlinedIcon style={{ color:iconColor }} htmlFor="email" className="icon" />
            </button>
            <button style={{ borderColor:textColor, backgroundColor:iconBoxColor }} onClick={handleLink} id="whatsapp">
              {" "}
              <BsWhatsapp style={{ color:iconColor }}  htmlFor="whatsapp" className="icon"  />
            </button>
            <button style={{ borderColor:textColor, backgroundColor:iconBoxColor }} onClick={handleLink} id="website">
              {" "}
              <LanguageIcon style={{ color:iconColor }} className="icon" />
            </button>
            <button style={{ borderColor:textColor, backgroundColor:iconBoxColor }} onClick={handleLink} id="address">
              {" "}
              <LocationOnIcon  style={{ color:iconColor }}className="icon" />
            </button>
            <button style={{ borderColor:textColor, backgroundColor:iconBoxColor }} onClick={handleLink} id="instagram">
              {" "}
              <InstagramIcon style={{ color:iconColor }} className="icon" />
            </button>
            <button style={{ borderColor:textColor, backgroundColor:iconBoxColor }} onClick={handleLink} id="facebook">
              {" "}
              <FacebookOutlinedIcon style={{ color:iconColor }} htmlFor="facebook" className="icon" />
            </button>
            <button style={{ borderColor:textColor, backgroundColor:iconBoxColor }} onClick={handleLink} id="linkedin">
              {" "}
              <LinkedInIcon style={{ color:iconColor }} className="icon" />
            </button>
            <button style={{ borderColor:textColor, backgroundColor:iconBoxColor }} onClick={handleLink} id="tiktok">
              {" "}
              <TbBrandTiktok style={{ color:iconColor }} className="icon" />
            </button>
            <button style={{ borderColor:textColor, backgroundColor:iconBoxColor }} onClick={handleLink} id="twitter">
              {" "}
              <TwitterIcon style={{ color:iconColor }} className="icon" />
            </button>



          </div>
        </div>

        

        <div style={boxStyle} className="bottom">
                 <div  className="buttons-container">
                   <button style={{ backgroundColor:backgroundColor }} className="bottom-button" onClick={handleLink} id="vcfLink">
                     <AddTaskOutlinedIcon style={{ color:textColor }} className="icon" id="vcfLink" />
                     <h3 style={{ color:textColor }} className="text" id="vcfLink">
                     {t('profile.add-contact')}
                     </h3>
                   </button>
                   <button style={{ backgroundColor:backgroundColor }}
                     className="bottom-button"
                     onClick={handleLink}
                     id="bottom-instagram-button"
                   >
                     <InstagramIcon style={{ color:textColor }} className="icon" />
                     <h3 style={{ color:textColor }} className="text"> {t('profile.instagram')}</h3>
                   </button>
                   <button
                   style={{ backgroundColor:backgroundColor }}
                     className="bottom-button"
                     onClick={handleLink}
                     id="bottom-facebook-button"
                   >
                     <FacebookOutlinedIcon style={{ color:textColor }} className="icon" />
                     <h3 style={{ color:textColor }} className="text">Facebook</h3>
                   </button>
                   <button
                   style={{ backgroundColor:backgroundColor }}
                     className="bottom-button"
                     onClick={handleCopyAdressText}
                     ref={textAddressRef}
                     id="bottom-address-button"
                   >
                     <LocationOnIcon style={{ color:textColor }} className="icon" />
                     <h3 style={{ color:textColor }} className="text" id="button-text">
                       {" "}
                     </h3>{" "}
                     <ContentCopyOutlinedIcon  style={{ color:textColor }} className="copy" />
                   </button>
                   <button
                   style={{ backgroundColor:backgroundColor }}
                     className="bottom-button"
                     onClick={handleCopyBankText}
                     ref={textBankRef}
                     id="bottom-bankIban-button"
                   >
                     <AccountBalanceOutlinedIcon style={{ color:textColor }} className="icon" />
                     <div className="text-container">
       
                     <h3 style={{ color:textColor }} className="text" id="bankName">
                     </h3>            
                     <h3  style={{ color:textColor }} className="text" id="bankIban">
                     </h3>
                     <h3 style={{ color:textColor }} className="text" id="bankRecipient">
                     </h3>
                     </div>
                     
                     <ContentCopyOutlinedIcon style={{ color:textColor }} className="copy" />
                   </button>
                   
                   <button
                   style={{ backgroundColor:backgroundColor }}
                     className="bottom-button"
                     onClick={handleCopyCompanyText}
                     ref={textCompanyRef}
                     id="bottom-officialName-button"
                   >
                     <ApartmentOutlinedIcon style={{ color:textColor }} className="icon" />
                     <div className="text-container">
                     <h2  style={{ color:textColor }} className="text" >
                     {t('profile.companyInformations')}
                     </h2>    
                     <h3 style={{ color:textColor }} className="text" id="officialName">
                     </h3>            
                     <h3 style={{ color:textColor }} className="text" id="officalAddress">
                     </h3>
                     <h3 style={{ color:textColor }} className="text" id="officialVAT">
                     </h3>
                     </div>
                     
                     <ContentCopyOutlinedIcon style={{ color:textColor }} className="copy" />
                   </button>
       
                   <button
                   style={{ backgroundColor:backgroundColor }}
                     className="bottom-button"
                     onClick={handleCopyCompanyText2}
                     ref={textCompanyRef2}
                     id="bottom-officialName2-button"
                   >
                     <ApartmentOutlinedIcon style={{ color:textColor }} className="icon" />
                     <div className="text-container">
                     <h2 style={{ color:textColor }} className="text" >
                     {t('profile.companyInformations')}
                     </h2>    
                     <h3 style={{ color:textColor }} className="text" id="officialName2">
                     </h3>            
                     <h3 style={{ color:textColor }} className="text" id="officalAddress2">
                     </h3>
                     <h3 style={{ color:textColor }} className="text" id="officialVAT2">
                     </h3>
                     </div>
                     
                     <ContentCopyOutlinedIcon style={{ color:textColor }} className="copy" />
                   </button>
       
                   <button
                   style={{ backgroundColor:backgroundColor }}
                     className="bottom-button"
                     onClick={handleLink}
                     id="bottom-trendyol-button"
                   >
                   <img src="/images/trendyol.png" className="marketplace"/>
                     <h3 style={{ color:textColor }} className="text">
                     {t('profile.trendyol')}
                     </h3>
                   </button>
                   <button
                   style={{ backgroundColor:backgroundColor }}
                     className="bottom-button"
                     onClick={handleLink}
                     id="bottom-hepsiburada-button"
                   >
                   <img src="/images/hepsiburada.png" className="marketplace"/>
                     <h3 style={{ color:textColor }} className="text">
                     {t('profile.hepsiburada')}
                     </h3>
                   </button>
                   <button
                   style={{ backgroundColor:backgroundColor }}
                     className="bottom-button"
                     onClick={handleLink}
                     id="bottom-sahibinden-button"
                   >
                   <img src="/images/sahibinden.png" className="marketplace"/>
                     <h3 style={{ color:textColor }} className="text">
                     {t('profile.sahibinden')}
                     </h3>
                   </button>
       
                   <button
                   style={{ backgroundColor:backgroundColor }}
                     className="bottom-button"
                     onClick={handleLink}
                     id="bottom-amazon-button"
                   >
                   <img src="/images/amazon.png" className="marketplace"/>
                     <h3 style={{ color:textColor }} className="text">
                     {t('profile.amazon')}
                     </h3>
                   </button>
       
                   
                 </div>
        </div>
       


        {/* Mozaik Template */}

        <div style={templateBoxContainerStyle} className="template-box-container">
        <button style={{ backgroundColor:backgroundColor }} className="template-box" onClick={handleLink} id="vcfLink">
         <AddTaskOutlinedIcon style={{ color:textColor }}  htmlFor="vcfLink" className="icon"  />
         
         </button>
         <button style={{ backgroundColor:backgroundColor }} className="template-box" onClick={handleLink} id="box-phone-button">
         <CallOutlinedIcon style={{ color:textColor }}  htmlFor="box-phone-button" className="icon"  />
         </button>
         <button style={{ backgroundColor:backgroundColor }} className="template-box" onClick={handleLink} id="box-whatsapp-button">
         <BsWhatsapp   style={{ color:textColor }}  htmlFor="box-whatsapp-button" className="icon"  />

         </button>
         <button style={{ backgroundColor:backgroundColor }} className="template-box" onClick={handleLink} id="box-website-button">
         <LanguageIcon style={{ color:textColor }}  htmlFor="website" className="icon"  />

         </button>
         <button style={{ backgroundColor:backgroundColor }} className="template-box" onClick={handleLink} id="box-email-button">
         <Mail style={{ color:textColor }}  htmlFor="email" className="icon"  />

         </button>
         <button style={{ backgroundColor:backgroundColor }} className="template-box" onClick={handleLink} id="box-address-button">
         <LocationOnIcon style={{ color:textColor }}  htmlFor="address" className="icon"  />

         </button>
         <button style={{ backgroundColor:backgroundColor }} className="template-box" onClick={handleLink} id="box-instagram-button">
         <Instagram style={{ color:textColor }}  htmlFor="box-instagram-button" className="icon"  />

         </button>
         <button style={{ backgroundColor:backgroundColor }} className="template-box" onClick={handleLink} id="box-facebook-button">
         <Facebook style={{ color:textColor }}  htmlFor="facebook" className="icon"  />

         </button>
         <button style={{ backgroundColor:backgroundColor }} className="template-box" onClick={handleLink} id="box-linkedin-button">
         <LinkedIn style={{ color:textColor }}  htmlFor="linkedin" className="icon"  />

         </button>
         <button style={{ backgroundColor:backgroundColor }} className="template-box" onClick={handleLink} id="box-tiktok-button">
         <BsTiktok style={{ color:textColor }}  htmlFor="tiktok" className="icon"  />

         </button>
         <button style={{ backgroundColor:backgroundColor }} className="template-box" onClick={handleLink} id="box-youtube-button">
         <YouTube style={{ color:textColor }}  htmlFor="box-youtube-button" className="icon"  />

         </button>
         <button style={{ backgroundColor:backgroundColor }}  className="template-box" onClick={handleCopyCompanyText} ref={textCompanyRef} id="box-officialName-button">
         <ApartmentOutlinedIcon style={{ color:textColor }}   className="icon"  />
         <div className="boxTextContainer">

         <h3 style={{ color:textColor }} className="boxText" id="BoxofficialName">
         </h3>            
          <h3 style={{ color:textColor }} className="boxText" id="BoxofficalAddress">
          </h3>
          <h3 style={{ color:textColor }} className="boxText" id="BoxofficialVAT">
          </h3>
        </div>
         </button>
         <button style={{ backgroundColor:backgroundColor }}  className="template-box" onClick={handleCopyCompanyText2} ref={textCompanyRef2} id="box-officialName2-button">
         <ApartmentOutlinedIcon style={{ color:textColor }}   className="icon"  />
         <div className="boxTextContainer">

         <h3 style={{ color:textColor }} className="boxText" id="BoxofficialName2">
         </h3>            
          <h3 style={{ color:textColor }} className="boxText" id="BoxofficalAddress2">
          </h3>
          <h3 style={{ color:textColor }} className="boxText" id="BoxofficialVAT2">
          </h3>
        </div>
         </button>
         <button style={{ backgroundColor:backgroundColor }} className="template-box" onClick={handleCopyBankText} ref={textBankRef} id="box-bankIban-button">
         <AccountBalanceOutlinedIcon style={{ color:textColor }}   className="icon"  />
         <div className="boxTextContainer">

          <h3 style={{ color:textColor }} className="boxText" id="BoxbankName">
          </h3>            
          <h3  style={{ color:textColor }} className="boxText" id="BoxbankIban">
          </h3>
          <h3 style={{ color:textColor }} className="boxText" id="BoxbankRecipient">
          </h3>
          </div>
         </button>
         <button style={{ backgroundColor:backgroundColor }}  className="template-box" id="box-sahibinden-button" onClick={handleLink} >

           <img src="/images/sahibinden.png" className="marketplace"/>

         </button>
         <button style={{ backgroundColor:backgroundColor }}  onClick={handleLink} id="box-amazon-button" className="template-box">
           <img src="/images/amazon.png" className="marketplace"/>

         </button>
         <div onClick={handleLink} id="box-hepsiburada-button" className="template-box">
         <img src="/images/hepsiburada.png" className="marketplace"/>

         </div>
         <button style={{ backgroundColor:backgroundColor }} onClick={handleLink} id="box-trendyol-button" className="template-box" >
         <img src="/images/trendyol.png" className="marketplace"/>

         </button>


       </div> 
  





         {showAddressModal && (
          <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <p>{t('modalAdress.addressCoppied')}</p>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closeAdressModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p id="modal-address">{modalAddressData}</p>
                  <button onClick={handleLink} id="address">
                    {" "}
                    <LocationOnIcon className="icon" />
                  </button>
                  <label className="button-label">
                  {t('modalAdress.openInGoogleMaps')}
                  </label>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={closeAdressModal}
                  >
                   {t('modalAdress.close')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showBankModal && (
          <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <p>{t('modalBank.bankCoppied')}</p>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closeBankModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p id="modal-bank">{modalBankData}</p>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={closeBankModal}
                  >
                   {t('modalBank.close')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showCompanyModal && (
          <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <p>{t('modalCompany.companyCoppied')}</p>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closeCompanyModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p id="modal-company">{modalCompanyData}</p>

                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={closeCompanyModal}
                  >
                   {t('modalCompany.close')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}  
        {showCompanyModal2 && (
          <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <p>{t('modalCompany.companyCoppied')}</p>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closeCompanyModal2}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p id="modal-company">{modalCompanyData2}</p>

                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={closeCompanyModal2}
                  >
                   {t('modalCompany.close')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}  
      </div>
    </div>
    
  );
};
export default Profile;
